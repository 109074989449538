import { NgIf } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { FocusDirective } from '../../../directives/focus.directive';

@Component({
	selector: 'button[ultra-button], span[ultra-button], a[ultra-button]',
	templateUrl: 'ultra-button.component.html',
	standalone: true,
	imports: [MatIconModule, NgIf, MatTooltipModule, TranslocoModule],
	host: {
		class:
			'border-transparent rounded-md h-fit flex px-2 py-1.5 justify-center gap-1 font-semibold duration-100 border',
	},
	hostDirectives: [FocusDirective],
})
export class UltraButtonComponent {
	@Input() disabled: boolean | null = null;
	@Input() svgIcon?: string;
	@Input() content?: string;

	@HostListener('click', ['$event'])
	onClick(event: MouseEvent) {
		event.stopPropagation();
	}

	@HostBinding('attr.disabled') get isDisabled() {
		return this.disabled === true ? '' : null;
	}

	@HostBinding('class') get className(): string {
		const enabledClasses = 'cursor-pointer hover:bg-hover';
		const disabledClasses = 'text-gray-500';

		return `${this.disabled ? disabledClasses : enabledClasses}`;
	}
}
